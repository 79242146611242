<template>
  <v-container fluid class="fill-height px-8">
    <v-row>
      <v-col>
        <h1>Admin Panel</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>Hier können die Kursanbieter und die Benutzer der Anbieter verwaltet werden.<br/>Zudem können Grundeinstellungen für die Applikation angepasst werden.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <operators></operators>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="mt-3">
          Benutzer verwalten

        </h2>
        <p>Zuerst einen Kursanbieter auswählen um die Benutzer dieses Anbieters zu verwalten.</p>

        <v-select
        v-model="operator"
        :items="operatorList"
        item-text="name"
        item-value="id"
        label="Kursanbieter wählen"
        >
        </v-select>
        <users
        :operator-id="operator"
        :from-admin="true">
        </users>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="mt-3">
          Administratoren verwalten
        </h2>
        <administrator-management></administrator-management>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="mt-3">
          Kurs-Kategorien Verwalten
        </h2>
        <categories>
        </categories>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <event-stats></event-stats>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="mt-3">
          CSV Vorlage
        </h2>
        <a v-if="this.csvTemplateUrl" :href="csvTemplateUrl" :download="csvTemplateName"><v-btn @click="" color="" class="grey-light mt-4 ml-2">CSV Vorlage herunterladen</v-btn></a>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import OperatorManagement from '../components/OperatorManagement'
import UserManagement from '../components/UserManagement'
import CategoryManagement from '../components/CategoryManagement'
import axios from 'axios'
import AdministratorManagement from "../components/AdministratorManagement.vue";
import EventStats from "../components/EventStats.vue";

export default {
  name: 'Admin',
  components: {
    EventStats,
    'operators': OperatorManagement,
    'users': UserManagement,
    'categories': CategoryManagement,
    'administrator-management': AdministratorManagement
  },
  data () {
    return {
      showError: false,
      operator: null,
      operatorList: [],
      csvTemplateUrl: '',
      csvTemplateName: ''
    }
  },
  created () {
    this.getOperators()

    this.getCsvTemplate()
  },
  methods: {
    getOperators () {
      axios
        .get('/management/operators/')
        .then((response) => {
          this.operatorList = response.data
        })
    },
    getCsvTemplate () {
      axios
        .get('/management/csvtemplate')
        .then((response) => {
          let csvHeaders = response.data

          let csvContent = "data:text/csv;charset=utf-8," + csvHeaders.join(';') + '\n'
          this.csvTemplateUrl = encodeURI(csvContent)
          this.csvTemplateName = 'deutschkurse_vorlage_import.csv'
        })
    }
  }
}
</script>
