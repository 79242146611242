<template>
  <div>
    <v-btn @click="addUser" color="primary" class="mt-4">Administrator hinzufügen</v-btn>

    <v-data-table
      :items="userList"
      :items-per-page="10"
      class="elevation-1 mt-6">
      <template v-slot:header="">
        <thead class="v-data-table-header">
        <tr>
          <th class="text-start">Name</th>
          <th class="text-start">Email</th>
          <th class="text-start">Aktionen</th>
        </tr>

        </thead>
      </template>
      <template v-slot:item="props">
        <tr>
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.username }}</td>

          <td nowrap="true">
            <v-icon small @click="editUser(props.item)" class="mr-2">mdi-pencil</v-icon>
            <v-icon v-if="$store.getters.user === props.item.username" small @click="editPassword(props.item)" class="mr-2">mdi-key</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog.user"
              persistent
              max-width="960px">
      <administrator-dialog
        ref="userActionDialog"
        :newUser="newUser"
        :newEdit="newEdit"
        :userId="userId"
        :changePassword="changePassword"
        @closeDialog="dialog.user = false"
        @getUsers="getUsers"
      >
      </administrator-dialog>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { showSnack } from '../globalActions'
import UserDialog from './UserDialog'
import AdministratorDialog from "./AdministratorDialog.vue";

export default {
  name: 'AdministratorManagement',
  props: [],
  components: {
    'administrator-dialog': AdministratorDialog
  },
  data () {
    return {
      dialog: {
        user: false,
        confirmDeleteUser: false
      },
      changePassword: false,
      userId: null,
      newUser: false,
      userList: [],
      urlPrefix: '',
      newEdit: 0
    }
  },
  watch: {
  },
  created () {
  },
  mounted () {
    this.getUsers()
  },
  methods: {
    getUsers () {
      axios
        .get('management/administrators')
        .then((response) => {
          this.userList = response.data
        })
    },
    editUser (user) {
      this.userId = user.id
      this.newEdit++
      this.newUser = false
      this.changePassword = false
      this.dialog.user = true
    },
    editPassword (user) {
      this.userId = user.id
      this.newUser = false
      this.changePassword = true
      this.dialog.user = true
    },
    addUser () {
      this.userId = null
      this.newUser = true
      this.dialog.user = true
    },
    confirmDeleteUser (operator) {
      this.editedOperator = operator
      this.dialog.confirmDeleteOperator = true
    },
    deleteUser () {
      axios.delete('/management/operators/' + this.editedOperator.id)
        .then((response) => {
          if (response.status === 204) {
            showSnack({ message: 'Kursanbieter wurde entfernt', color: 'success' })
            this.getOperators()
            this.dialog.confirmDeleteOperator = false
          } else {
            showSnack({ message: 'Kursanbieter konnte nicht entfernt werden' })
            this.dialog.confirmDeleteOperator = false
          }
        })
    }
  }
}
</script>
