<template>
  <div>
    <h2 class="mt-3">
      Kursanbieter verwalten
    </h2>
    <v-btn @click="dialog.createOperator = true" color="primary" class="mt-4">Kursanbieter hinzufügen</v-btn>

    <v-data-table
      :items="operatorList"
      :items-per-page="10"
      class="elevation-1 mt-6">
      <template v-slot:header="">
        <thead class="v-data-table-header">
          <tr>
            <th class="text-start">Name</th>
            <th class="text-start">Aktiv</th>
            <th class="text-start">Aktionen</th>
          </tr>
        </thead>
      </template>
      <template v-slot:item="props">
        <tr>
          <td>{{ props.item.name }}</td>
          <td nowrap="true">
            <v-simple-checkbox
              v-model="props.item.active"
              disabled
            ></v-simple-checkbox>
          </td>
          <td nowrap="true">
            <v-icon small @click="editOperator(props.item)" class="mr-2">mdi-pencil</v-icon>
<!--            <v-icon small @click="confirmDeleteOperator(props.item)">mdi-delete</v-icon>-->
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog.createOperator"
              persistent
              max-width="960px">
      <v-card>
        <v-card-title>
          <span class="headline">Kursanbieter erstellen</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="form.createOperator.name"
                  label="Name"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog.createOperator = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="createOperator()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.editOperator"
              persistent
              max-width="960px">
      <v-card>
        <v-card-title>
          <v-container>
            <span class="headline">Kursanbieter bearbeiten</span>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="form.editOperator.name"
                  label="Name"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-checkbox
                  v-model="form.editOperator.active"
                  label="Aktiv"
                  required
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog.editOperator = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveOperator()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.confirmDeleteOperator" max-width="400">
      <v-card>
        <v-card-title class="text-h6">Diesen Anbieter entfernen?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog.confirmDeleteOperator = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="deleteOperator">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { showSnack } from '../globalActions'

export default {
  name: 'OperatorManagement',

  data () {
    return {
      dialog: {
        createOperator: false,
        confirmDeleteOperator: false,
        editOperator: false
      },
      form: {
        createOperator: {
          name: ''
        },
        editOperator: {
          name: '',
          active: false
        }
      },
      editedOperator: null,
      operatorList: []
    }
  },
  created () {
  },
  mounted () {
    this.getOperators()
  },
  methods: {
    getOperators () {
      axios
        .get('/management/operators/')
        .then((response) => {
          this.operatorList = response.data
        })
    },
    confirmDeleteOperator (operator) {
      this.editedOperator = operator
      this.dialog.confirmDeleteOperator = true
    },
    deleteOperator () {
      axios.delete('/management/operators/' + this.editedOperator.id)
        .then((response) => {
          if (response.status === 204) {
            showSnack({ message: 'Kursanbieter wurde entfernt', color: 'success' })
            this.getOperators()
            this.dialog.confirmDeleteOperator = false
          } else {
            showSnack({ message: 'Kursanbieter konnte nicht entfernt werden' })
            this.dialog.confirmDeleteOperator = false
          }
        })
    },
    createOperator () {
      axios.post('/management/operators/', this.form.createOperator)
        .then((response) => {
          if (response.status === 204) {
            showSnack({ message: 'Kursanbieter wurde erstellt', color: 'success' })
            this.getOperators()
            this.dialog.createOperator = false
          } else {
            showSnack({ message: 'Fehler! Kursanbieter konnte nicht erstellt werden' })
          }
        })
        .finally()
    },
    editOperator (operator) {
      this.editedOperator = operator
      this.form.editOperator = operator
      this.dialog.editOperator = true
    },
    saveOperator () {
      axios.patch('/management/operators/' + this.editedOperator.id, this.form.editOperator)
        .then((response) => {
          if (response.status === 204) {
            showSnack({ message: 'Kursanbieter wurde aktualisiert', color: 'success' })
            this.getOperators()
            this.dialog.editOperator = false
          } else {
            showSnack({ message: 'Fehler! Kursanbieter konnte nicht aktualisiert werden' })
          }
        })
        .finally()
    }
  }
}
</script>
