<template>
  <div>
    <v-btn @click="dialog.createCategory = true" color="primary" class="mt-4">Kategorie hinzufügen</v-btn>

    <v-data-table
      :items="categoryList"
      :items-per-page="10"
      class="elevation-1 mt-6">
      <template v-slot:header="">
        <thead class="v-data-table-header">
        <tr>
          <th class="text-start">Name</th>
          <th class="text-start">Beschreibung</th>
          <th class="text-start">Import-Referenz</th>
          <th class="text-start">Aktionen</th>
        </tr>

        </thead>
      </template>
      <template v-slot:item="props">
        <tr>
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.description }}</td>
          <td>{{ props.item.importReference }}</td>
          <td nowrap="true">
            <v-icon small @click="editCategory(props.item)" class="mr-2">mdi-pencil</v-icon>
<!--            <v-icon small @click="confirmDeleteCategory(props.item)">mdi-delete</v-icon>-->
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog.createCategory"
              persistent
              max-width="960px">
      <v-card>
        <v-card-title>
          <span class="headline">Kategorie erstellen</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="form.createCategory.name"
                  label="Name"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="form.createCategory.description"
                  label="Beschreibung"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="form.createCategory.importReference"
                  label="Import-Referenz"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog.createCategory = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="createCategory()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.editCategory"
              persistent
              max-width="960px">
      <v-card>
        <v-card-title>
          <v-container>
            <span class="headline">Kursanbieter bearbeiten</span>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="form.editCategory.name"
                  label="Name"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="form.editCategory.description"
                  label="Beschreibung"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="form.editCategory.importReference"
                  label="Import-Referenz"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-checkbox
                  v-model="form.editCategory.active"
                  label="Aktiv"
                  required
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog.editCategory = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveCategory()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.confirmDeleteCategory" max-width="400">
      <v-card>
        <v-card-title class="text-h6">Diesen Kursanbieter entfernen?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog.confirmDeleteCategory = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="deleteCategory">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { showSnack } from '../globalActions'

export default {
  name: 'Locations',

  data () {
    return {
      dialog: {
        createCategory: false,
        confirmDeleteCategory: false,
        editCategory: false
      },
      form: {
        createCategory: {
          name: '',
          description: '',
          importReference: ''
        },
        editCategory: {
          name: '',
          active: false
        }
      },
      editedCategory: null,
      categoryList: []
    }
  },
  created () {
  },
  mounted () {
    this.getCategories()
  },
  methods: {
    getCategories () {
      axios
        .get('/management/categories/')
        .then((response) => {
          this.categoryList = response.data
        })
    },
    confirmDeleteCategory (category) {
      this.editedCategory = category
      this.dialog.confirmDeleteCategory = true
    },
    deleteCategory () {
      axios.delete('/management/categories/' + this.editedCategory.id)
        .then((response) => {
          if (response.status === 200) {
            showSnack({ message: 'Kategorie wurde entfernt', color: 'success' })
            this.getCategories()
            this.dialog.confirmDeleteCategory = false
          } else {
            showSnack({ message: 'Kategorie konnte nicht entfernt werden' })
            this.dialog.confirmDeleteCategory = false
          }
        })
    },
    createCategory () {
      axios.post('/management/categories/', this.form.createCategory)
        .then((response) => {
          if (response.status === 200) {
            showSnack({ message: 'Kategorie wurde erstellt', color: 'success' })
            this.getCategories()
            this.dialog.createCategory = false
          } else {
            showSnack({ message: 'Fehler! Kategorie konnte nicht erstellt werden' })
          }
        })
        .finally()
    },
    editCategory (category) {
      this.editedCategory = category
      this.form.editCategory = category
      this.dialog.editCategory = true
    },
    saveCategory () {
      axios.patch('/management/categories/' + this.editedCategory.id, this.form.editCategory)
        .then((response) => {
          if (response.status === 200) {
            showSnack({ message: 'Kategorie wurde aktualisiert', color: 'success' })
            this.getCategories()
            this.dialog.editCategory = false
          } else {
            showSnack({ message: 'Fehler! Kategorie konnte nicht aktualisiert werden' })
          }
        })
        .finally()
    }
  }
}
</script>
