import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"fill-height px-8",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,[_c('h1',[_vm._v("Admin Panel")])])],1),_c(VRow,[_c(VCol,[_c('p',[_vm._v("Hier können die Kursanbieter und die Benutzer der Anbieter verwaltet werden."),_c('br'),_vm._v("Zudem können Grundeinstellungen für die Applikation angepasst werden.")])])],1),_c(VRow,[_c(VCol,[_c('operators')],1)],1),_c(VRow,[_c(VCol,[_c('h2',{staticClass:"mt-3"},[_vm._v(" Benutzer verwalten ")]),_c('p',[_vm._v("Zuerst einen Kursanbieter auswählen um die Benutzer dieses Anbieters zu verwalten.")]),_c(VSelect,{attrs:{"items":_vm.operatorList,"item-text":"name","item-value":"id","label":"Kursanbieter wählen"},model:{value:(_vm.operator),callback:function ($$v) {_vm.operator=$$v},expression:"operator"}}),_c('users',{attrs:{"operator-id":_vm.operator,"from-admin":true}})],1)],1),_c(VRow,[_c(VCol,[_c('h2',{staticClass:"mt-3"},[_vm._v(" Administratoren verwalten ")]),_c('administrator-management')],1)],1),_c(VRow,[_c(VCol,[_c('h2',{staticClass:"mt-3"},[_vm._v(" Kurs-Kategorien Verwalten ")]),_c('categories')],1)],1),_c(VRow,[_c(VCol,[_c('event-stats')],1)],1),_c(VRow,[_c(VCol,[_c('h2',{staticClass:"mt-3"},[_vm._v(" CSV Vorlage ")]),(this.csvTemplateUrl)?_c('a',{attrs:{"href":_vm.csvTemplateUrl,"download":_vm.csvTemplateName}},[_c(VBtn,{staticClass:"grey-light mt-4 ml-2",attrs:{"color":""},on:{"click":function($event){}}},[_vm._v("CSV Vorlage herunterladen")])],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }