<template>
  <div>
    <v-card>
      <v-card-title>
        <span v-if="userId" class="headline">Administrator bearbeiten</span>
        <span v-else class="headline">Administrator erstellen</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <validation-observer
            ref="userFormObserver"
            v-slot="{ }"
          >
          <div v-if="!changePassword || newUser">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="Name"
                  rules="required"
                >
                <v-text-field v-model="form.user.name" label="Name" :error-messages="errors"></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="Username"
                  rules="required|email"
                >
                <v-text-field v-model="form.user.username" :disabled="!newUser" label="Email" :error-messages="errors"></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
          </div>
          <v-row v-if="changePassword">
            <v-col>
              <p class="my-4">Neues Passwort für diesen Administrator setzen:</p>
            </v-col>
          </v-row>
          <v-row v-if="changePassword || newUser">
            <v-col cols="12" sm="6" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Password"
                :rules="{required: true, min: 8}"
              >
              <v-text-field type="password" v-model="form.user.password" ref="password" label="Passwort" :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="ConfirmPassword"
                rules="required|password:@Password"
              >
              <v-text-field type="password" v-model="form.user.confirmPassword" ref="confirmPassword" label="Passwort bestätigen" :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          </validation-observer>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="resetForm(); $emit('closeDialog')"
        >
          Abbrechen
        </v-btn>
        <v-btn v-if="userId"
               color="primary"
               text
               @click="updateUser()"
        >
          Aktualisieren
        </v-btn>
        <v-btn v-else
               color="primary"
               text
               @click="createUser()"
        >
          Administrator erstellen
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import { showSnack } from '../globalActions'
// eslint-disable-next-line camelcase
import { required, email, regex, required_if, confirmed, min } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'Dies ist ein Pflichtfeld'
})

extend('email', {
  ...email,
  message: 'Es muss eine Email Adresse eingegeben werden'
})

extend('regex', {
  ...regex,
  message: 'Die Eingabe entspricht nicht dem erwarteten Format'
})

extend('confirmed', {
  ...regex,
  message: 'Das eingegebene Passwort stimmt nicht überein'
})

extend('min', {
  ...min,
  message: 'Das Passwort muss mindestens 8 Zeichen lang sein.'
})

extend('password', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: 'Das eingegebene Passwort stimmt nicht überein'
})

export default {
  name: 'AdministratorDialog',
  props: ['newUser', 'userId', 'changePassword', 'newEdit'],
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: function () {
    return {
      eventDialogVisible: false,
      form: {
        user: this.userForm()
      },
    }
  },
  mounted () {

  },
  watch: {
    newEdit: function () {
      this.getUser()
    }
  },
  methods: {
    createUser () {
      this.$refs.userFormObserver.validate()
        .then((valid) => {
          if (valid) {
            axios.post('management/administrators', this.form.user)
              .then((response) => {
                if (response.status === 204) {
                  this.resetForm()
                  this.$emit('closeDialog')
                  this.$emit('getUsers')
                  showSnack({message: 'Administrator wurde erstellt', color: 'success'})
                } else {
                  showSnack({message: 'Fehler! Administrator konnte nicht erstellt werden'})
                }
              })
              .finally()
          } else {
            showSnack({ message: 'Das Formular ist nicht korrekt ausgefüllt' })
          }
        })
    },
    getUser () {
      axios.get('management/administrators/' + this.userId)
        .then((response) => {
          if (response.data) {
            this.form.user.name = response.data.name
            this.form.user.username = response.data.username
          } else {
            showSnack({ message: 'Fehler! Administrator konnte nicht gefunden werden' })
          }
        })
        .finally()
    },
    updateUser () {
      this.$refs.userFormObserver.validate()
        .then((valid) => {
          if (valid) {
            axios.patch('management/administrators/' + this.userId, this.form.user)
              .then((response) => {
                if (response.status >= 200 && response.status <= 208) {
                  this.resetForm()
                  showSnack({ message: 'Administrator wurde aktualisiert', color: 'success' })
                  this.$emit('closeDialog')
                  this.$emit('getUsers')
                } else {
                  showSnack({ message: 'Administrator konnte nicht aktualisiert werden' })
                }
              })
          } else {
            showSnack({ message: 'Das Formular ist nicht korrekt ausgefüllt' })
          }
        })
    },
    resetForm () {
      this.form.user = this.userForm()
    },
    userForm: () => {
      return {
        name: '',
        username: '',
        password: '',
        confirmPassword: '',
      }
    }
  }
}
</script>

<style scoped>

</style>
